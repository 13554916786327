<template>
    <v-layout>
        <v-flex xs2>
            <v-btn
                fab
                dark

                @click="openComment"
            >
                <v-icon
                    dark
                >
                    comment
                </v-icon>
            </v-btn>
        </v-flex>
        <v-flex>
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="time"
                        :label= "$t('time.startTime')"
                        :disabled="valid"
                        class="centered-input custom"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                </template>
                <v-time-picker
                    v-if="menu"
                    v-model="time"
                    format="24hr"
                    full-width
                    @click:minute="$refs.menu.save(time)"
                ></v-time-picker>
            </v-menu>
        </v-flex>
        <v-flex>
            <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="time2"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="time2"
                        :label= "$t('time.stopTime')"
                        :disabled="valid"
                        class="centered-input custom"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                </template>
                <v-time-picker
                    v-if="menu2"
                    v-model="time2"
                    format="24hr"
                    full-width
                    @click:minute="$refs.menu2.save(time2)"
                ></v-time-picker>
            </v-menu>
        </v-flex>
        <v-subheader
            v-if="showWarning">
            {{ $t("time.warning") }}
        </v-subheader>
    </v-layout>
</template>

<script>
import moment from 'moment'

export default {
    name: 'timeInput',
    props: {
        singleCount: {
            type: Number,
            default () {
                return 0
            }
        },
        optional: {
            type: Object,
            default () {
                return {}
            }
        },
        valid: {
            type: Boolean,
            default () {
                return false
            }
        }
    },
    data () {
        return {
            time: '',
            menu: false,
            time2: '',
            menu2: false,
            dateheure_creation: '',
            showWarning: false
        }
    },
    watch: {
        time2: function () {
            if (this.time2 < this.time) {
                this.showWarning = true
            } else {
                this.showWarning = false
            }
        },
        timeDiff: function () {
            if (this.timeDiff > 0) {
                this.incrementSingle()
            }
        }
    },
    computed: {
        timeDiff () {
            const startTime = moment(this.time, 'HH:mm')
            const endTime = moment(this.time2, 'HH:mm')
            let duration = this.singleCount
            if (this.time2 < this.time) {
                duration = (24 * 60) - startTime.diff(endTime, 'minutes')
            } else {
                duration = endTime.diff(startTime, 'minutes')
            }
            if (this.singleCount > 0 && duration === 0) {
                duration = this.singleCount
                return Number(duration)
            }
            return Number((duration / 60).toFixed(2))
        }
    },
    created () {
        this.time = this.optional.startTime ?? moment().format('HH:mm')
        this.time2 = this.optional.stopTime ?? moment().format('HH:mm')
        this.dateheure_creation = this.optional.dateheure_creation ?? ''
    },
    methods: {
        openComment () {
            this.$emit('openComment')
        },
        incrementSingle () {
            this.$emit('incrementSingle', {
                count: this.timeDiff,
                optional: {
                    startTime: this.time,
                    stopTime: this.time2,
                    dateheure_creation: this.dateheure_creation
                }
            })
        }
    }
}
</script>
<style>
.theme--light.v-subheader {
    color: #9B0000;
    font-weight: bold;
    border-style: solid;
    border-width: 2px;
    height: 55px;
    padding: 16px 16px 16px 16px;
}
</style>
